import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import Style from './Styles/NewsFeedStyle'
import Settings from '../../Settings/Settings';
import NewsItem from './Subcomponents/NewsItem';
import CircularProgress from '../CircularProgress/CircularProgress';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography';
import color from '@mui/material/colors/blue';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export default class LatestNewsFeed extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.componentMethods.getManagerState().language,
        }
    }

    componentDidMount() {
        super.componentDidMount()
        this.props.componentMethods.getSubscribersFeedAction()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.componentMethods.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.componentMethods.getManagerState()
            },
            changeRoute: (route) => {
                that.props.componentMethods.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },

            addError: (error, invalidFields = {}) => {
                that.addError(error, invalidFields)
            },
            addErrorMain: (error, invalidFields = {}) => {
                that.addErrorMain(error, invalidFields)
            },
            clearErrorMessage: (index) => {
                that.props.componentMethods.clearErrorMessage(index)
            },
            getSubscribersFeedAction: () => {
                that.props.componentMethods.getSubscribersFeedAction()
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}, errorCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback, errorCallback);
                },
        }).setComponentsConstants('LoginForm', {
            ...this.basicComponentConstants
        }).setComponentsMethods('LoginForm', {
            setManagerState: (object, callback) => {
                that.props.componentMethods.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.componentMethods.getManagerState()
            },
            changeRoute: (route) => {
                that.props.componentMethods.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}, errorCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback, errorCallback);
                },
            saveLocalStorage: (storageObject, storageName) => {
                that.saveLocalStorage(storageObject, storageName)
            }
        })
    }
    getOldestSubscriberLinksID () {
        let feedsItems = this.props.componentMethods.getManagerState().subscribersLatestFeedItems;
        let lastID = 0
        for (let i in feedsItems) {

            lastID = feedsItems[i].linksID
        }
        return lastID
    }
    getNewsOlderLatestFeedAction() {
        const that = this;

        that.props.componentMethods.setManagerState({loadingOlderLatestNewsFeed: true},() => {
            if(that.getLocalStorage('user')) {
                that.ajax(Settings.API_URL + Settings.API_ENDPOINT_SUBSCRIBERS_FEED_LATEST_OLDER + that.getOldestSubscriberLinksID(),{},'GET','subscribersFeed', (data) => {
                    let subscribersLatestFeedItems = that.props.componentMethods.getManagerState().subscribersLatestFeedItems
                    subscribersLatestFeedItems = subscribersLatestFeedItems.concat(data.items)
                    that.props.componentMethods.setManagerState({loadingOlderLatestNewsFeed: false, subscribersLatestFeedItems: subscribersLatestFeedItems})
                })
            }    
        })
    }
    getRender() {
        const classes = Style();
        this.loadComponentsProps()
        return <div style={classes.rootNewsFeed}>                               
                    <Grid container spacing={2}>
                        {this.props.componentMethods.getManagerState().loadingLatestNewsFeed?
                            <Grid item xs={12} md={12} lg={12}  style={{textAlign: "center"}}>
                                <div style={classes.loadingOlder}>
                                    <CircularProgress style={classes.cilcularProgress} />
                                </div>
                            </Grid>
                            :
                            null
                        }
                    {
                        this.props.componentMethods.getManagerState().subscribersLatestFeedItems.map((item, index)=> {
                            return <Grid item xs={12} md={12} lg={12} key={index}>
                                <NewsItem showBookmark={true} link={item} key={index} {...this.buildComponentProps("Appbar")} />
                            </Grid>
                        })
                    }
                    {this.props.componentMethods.getManagerState().loadingOlderLatestNewsFeed?
                        <Grid item xs={12} md={12} lg={12}  style={{textAlign: "center"}}>
                            <div style={classes.loadingOlder}>
                                <CircularProgress style={classes.cilcularProgress} />
                            </div>
                        </Grid>
                        :
                        <Grid item xs={12} md={12} lg={12} style={classes.loadMoreButton}>
                            <Button color="secondary" variant="outlined" onClick={() => {this.getNewsOlderLatestFeedAction()}}>{this.props.componentMethods.getManagerState().language.LOAD_MORE}</Button>
                        </Grid>
                    }
                    </Grid>
                </div>
    }
}
