import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Drawer from '@mui/material/Drawer';
import ListItemText from '@mui/material/ListItemText';
import Style from '../../NewsFeed/Styles/NewsFeedStyle'
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled, useTheme } from '@mui/material/styles';
import TuneIcon from '@mui/icons-material/Tune';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import Progress from '../../CircularProgress/CircularProgress';
import Typography from '@mui/material/Typography'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));
const drawerWidth = 350;

export default function TrendingDrawer(props) {
  const theme = useTheme();
  const classes = Style();
  const componentConstants = {
      ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [filterSubscribers, setFilterSubscribers] = React.useState("")
  const [open, setOpen] = React.useState(false);
  const [askDelete, setAskDelete] = React.useState({})
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const isWordSubscribed = (word) => {
    let isWordSubscribed = false;
    componentMethods.getManagerState().subscribers.map((item, index) => {
        if(item.subscribersKeyword.toLowerCase() === word.toLowerCase()) {
            isWordSubscribed = true
        }
    })
    return isWordSubscribed;
  }

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor} style={{zIndex: 100000}}>
          <Fab color="secondary" onClick={() => {handleDrawerOpen(anchor, true)}} style={classes.trendingDrawer} aria-label="add">
            <WhatshotIcon />
          </Fab>
          <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
        <DrawerHeader style={{position: "sticky", top: 0, backgroundColor:"#fff", zIndex: 10000}}>
          <Typography variant="h5" component="h5" style={classes.title}>
            {componentMethods.getManagerState().language.LANG_TRENDING_TODAY}
          </Typography>
        <IconButton onClick={handleDrawerClose}>
           <ChevronRightIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <ListItem key={componentConstants.language.LANG_BOOKMARKS} disablePadding>
          <Divider />
        </ListItem>
            {
              componentMethods.getManagerState().trendingWordsDrawer.map((item, index)=> {
                 
                return <React.Fragment>
                        <ListItem key={index} disablePadding>
                          <Typography component="span" variant='subtitle1' style={{padding: 8}}>{index+1}</Typography>
                          {!isWordSubscribed(item.trendingWordsKeyword) ?
                                <IconButton color="secondary" variant="outlined" style={classes.addToWatchlistButton} onClick={() => {componentMethods.postSubscriberKeyword(item.trendingWordsKeyword)}}><TuneIcon /></IconButton>
                              :
                              null
                          }
                          <a href={"/search/" + item.trendingWordsKeyword} color="primary" style={classes.menuDrawerLinks}>
                            <ListItemButton color="primary">
                              <ListItemText primary={item.trendingWordsKeyword} />
                            </ListItemButton>
                          </a> 
                        </ListItem>
                        <Divider />
                      </React.Fragment>
              })
          }
        <ListItem disablePadding>
        {componentMethods.getManagerState().loadingTrendingWordsDrawer ?
            <Progress />
            :
            <Button style={{margin: "8px auto"}} color="secondary" variant="outlined" onClick={() => {componentMethods.getTrendingWordsAction()}}>{componentMethods.getManagerState().language.LOAD_MORE}</Button>
        }
        </ListItem>
      </List>
    </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}