import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import Settings from '../../Settings/Settings';
import AppbarSubcomponent from './Subcomponents/Appbar'
import Style from './Styles/AppbarStyle'
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';

export default class Appbar extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.componentMethods.getManagerState().language,
            urlParams: props.componentConstants.urlParams
        }
    }

    componentDidMount() {
        super.componentDidMount()
        this.getSubscribersAction()
        this.getTrendingWordsAction()
        this.getFeedsAction()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.componentMethods.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.componentMethods.getManagerState()
            },
            hasAccess: (accessLevel) => {
                return that.hasAccess(accessLevel)
            },
            changeRoute: (route) => {
                that.props.componentMethods.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            saveLocalStorage: (storageObject, storageName) => {
                that.saveLocalStorage(storageObject, storageName)
            },
            getSubscribersFeedAction: () => {
                that.props.componentMethods.getSubscribersFeedAction()
            },
            getLinksAction: (q) => {
                that.props.componentMethods.getLinksAction(q)
            },
            postSubscriberKeyword: (keyword) => {
                that.postSubscriberKeyword(keyword)
            },
            deleteSubscriberKeyword: (subscribersID) => {
                that.deleteSubscriberKeyword(subscribersID)
            },
            getTrendingWordsAction: () => {
                that.getTrendingWordsAction()
            },
            getFeedsAction: (q, p) => {
                that.getFeedsAction(q, p)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}, errorCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback, errorCallback);
            },
        })
    }

    getTrendingWordsAction() {
        const that = this;
        const date = new Date();
        let page = that.props.componentMethods.getManagerState().trendingWordsPagination.current + 1
        const dateYear = date.getFullYear();
        let dateMonth = date.getMonth();
        dateMonth++;
        if(date.getMonth() < 10) {
            dateMonth = '0' + dateMonth
        }
        let dateDay = date.getDate();
        if(dateDay < 10) {
            dateDay = '0' + dateDay;
        }
        let searchDate = dateYear + '-' + dateMonth + '-' + dateDay;
        that.props.componentMethods.setManagerState({loadingTrendingWordsDrawer: true},() => {
            that.ajax(Settings.API_URL + Settings.API_ENDPOINT_TRENDING_WHITELISTED_TODAY + '?limit=50&page=' + page   ,{},'GET',null, (data) => {
                if(data.items.length) {
                    if (that.props.componentMethods.getManagerState().trendingWordsDrawer.length > 0)
                    {
                        let trendingWords = that.props.componentMethods.getManagerState().trendingWordsDrawer
                        trendingWords = trendingWords.concat(data.items)
                        that.props.componentMethods.setManagerState({trendingWordsDrawer: trendingWords, loadingTrendingWordsDrawer:false, trendingWordsPagination: data.pagination})
                    } else {
                        that.props.componentMethods.setManagerState({trendingWordsDrawer: data.items, loadingTrendingWordsDrawer:false, trendingWordsPagination: data.pagination})
                    }
                } else {
                    that.props.componentMethods.setManagerState({loadingTrendingWordsDrawer:false})
                }
            })
        })
    }

    postSubscriberKeyword(subscribersKeyword) {
        const that = this;
        let postObj = {
            subscribersKeyword: subscribersKeyword,
            subscribersDeliveryPeriod: 0,
            subscribersActive: 1
        }
        if(that.getLocalStorage('user')) {
            that.ajax(Settings.API_URL + Settings.API_ENDPOINT_SUBSCRIBERS + '?accountToken=' + that.getLocalStorage('user').tokensHash,postObj,'POST',null, (data) => {
                let subscribers = that.props.componentMethods.getManagerState().subscribers;
                subscribers = subscribers.concat(data.items[0])
                that.props.componentMethods.setManagerState({subscribers: subscribers})
            })
        }    
    }

    deleteSubscriberKeyword(subscribersID) {
        const that = this;
        if(that.getLocalStorage('user')) {
            that.ajax(Settings.API_URL + Settings.API_ENDPOINT_SUBSCRIBERS + '/' + subscribersID + '?accountToken=' + that.getLocalStorage('user').tokensHash,{},'DELETE',null, (data) => {
                that.getSubscribersAction()
            })
        }    
    }
    getSubscribersAction() {
        const that = this;
        that.props.componentMethods.setManagerState({loadingSubscribers: true},() => {
            if(that.getLocalStorage('user')) {
                that.ajax(Settings.API_URL + Settings.API_ENDPOINT_SUBSCRIBERS,{},'GET','subscribersFeed', (data) => {
                    that.props.componentMethods.setManagerState({subscribers: data.items, loadingSubscribers: false})
                })
            }    
        })
    }
    getFeedsAction(q = null, page = '') {
        const that = this;

        if (page === '')
            page = that.props.componentMethods.getManagerState().feedsPagination.current + 1
        if (q === null)
            q = that.props.componentMethods.getManagerState().feedsQuery
        that.props.componentMethods.setManagerState({loadingFeeds: true},() => {
            that.ajax(Settings.API_URL + Settings.API_ENDPOINT_GET_FEEDS + '?q=' + q + '&orderBy=feedsID&page=' + page,{},'GET','feeds', (data) => {
                if(data.items.length) {
                    if (that.props.componentMethods.getManagerState().feeds.length > 0 && page !== 1)
                    {
                        let feeds = that.props.componentMethods.getManagerState().feeds
                        feeds = feeds.concat(data.items)
                        that.props.componentMethods.setManagerState({feedsQuery: q,feeds: feeds, loadingFeeds:false, feedsPagination: data.pagination})
                    } else {
                        that.props.componentMethods.setManagerState({feedsQuery: q, feeds: data.items, loadingFeeds:false, feedsPagination: data.pagination})
                    }
                } else {
                    that.props.componentMethods.setManagerState({loadingFeeds:false})
                }
            })
        })
    }
    getRender() {
        const classes = Style()
        this.loadComponentsProps()
        return <div style={classes.root} id="rootComponent">
                    <AppbarSubcomponent postWebsiteVisitors={this.props.postWebsiteVisitors} {...this.buildComponentProps("Appbar")} />
                    {this.props.componentMethods.getManagerState().errors.map((element, index) => { 
                        return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={this.props.componentMethods.clearErrorMessage}/>
                     })}
                </div>
    }
}
