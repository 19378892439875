import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Drawer from '@mui/material/Drawer';
import ListItemText from '@mui/material/ListItemText';
import Style from '../../NewsFeed/Styles/NewsFeedStyle'
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled, useTheme } from '@mui/material/styles';
import TuneIcon from '@mui/icons-material/Tune';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import Progress from '../../CircularProgress/CircularProgress';
import Typography from '@mui/material/Typography'
import RssFeedIcon from '@mui/icons-material/RssFeed';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SearchIcon from '@mui/icons-material/Search';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));
const drawerWidth = 350;

export default function FeedsDrawer(props) {
  const theme = useTheme();
  const classes = Style();
  const componentConstants = {
      ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [filterSubscribers, setFilterSubscribers] = React.useState("")
  const [open, setOpen] = React.useState(false);
  const [askDelete, setAskDelete] = React.useState({})
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Fab color="secondary" onClick={() => {handleDrawerOpen(anchor, true)}} style={classes.feedsDrawer} aria-label="add">
            <RssFeedIcon />
          </Fab>
          <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
        <DrawerHeader style={{position: "sticky", top: 0, backgroundColor:"#fff", zIndex: 10000}}>
          <IconButton onClick={() => {componentMethods.getFeedsAction(filterSubscribers, 1)}}>
            <SearchIcon />
          </IconButton>
          <TextField id="outlined-basic" color="secondary" fullWidth label={componentConstants.language.LANG_FILTER_FEEDS} variant="outlined" onChange={(e) => { setFilterSubscribers(e.target.value)}}/>  
          <IconButton onClick={handleDrawerClose}>
            <ChevronRightIcon />
          </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <ListItem key={componentConstants.language.LANG_BOOKMARKS} disablePadding>
          <Divider />
        </ListItem>
            {
              componentMethods.getManagerState().feeds.map((item, index)=> {
                if(item.feedsName.toLowerCase().trim().indexOf(filterSubscribers.toLowerCase().trim()) >= 0 || item.feedsUrl.toLowerCase().trim().indexOf(filterSubscribers.toLowerCase().trim()) >= 0) {
                  return <React.Fragment>
                          <ListItem key={index} disablePadding>
                            <Typography component="span" variant='subtitle1' style={{padding: 8}}>{index+1}</Typography>
                            <a href={"/feed/" + item.feedsID} color="primary" style={classes.menuDrawerLinks}>
                              <ListItemButton color="primary">
                                <ListItemText primary={item.feedsName} />
                              </ListItemButton>
                            </a> 
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                }
              })
          }
        <ListItem disablePadding>
        {componentMethods.getManagerState().loadingFeeds ?
            <Progress />
            :
            <Button style={{margin: "8px auto"}} color="secondary" variant="outlined" onClick={() => {componentMethods.getFeedsAction()}}>{componentMethods.getManagerState().language.LOAD_MORE}</Button>
        }
        </ListItem>
      </List>
    </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}