import React, { useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Settings from '../../../Settings/Settings'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';

export default function AddFeedForm(props) {
    const [feedsID, setFeedsID] = React.useState(null)
    const [feedsUrl, setFeedsUrl] = React.useState(null)
    const [feedsName, setFeedsName] = React.useState(null)
    const [feedsLastCrawled, setFeedsLastCrawled] = React.useState(null)
    const [feedsNewsTypesID, setFeedsNewsTypesID] = React.useState(null)
    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const componentProps = {
        ...props.componentProps
    }
    const handleChangeFeedsName = (e) =>
    {
        setFeedsName(e.target.value)
    }
    const handleChangeFeedsUrl = (e) =>
        {
            setFeedsUrl(e.target.value)
        }
    const handleChangeNewsTypesID = (e) =>
        {
            setFeedsNewsTypesID(e.target.value)
        }
    const postFeedAction = () => {
        const that = this;
        let putObject = {feedsUrl:feedsUrl, feedsActive: 1, feedsName: feedsName}
        if (feedsNewsTypesID)
        {
            putObject.feedsNewsTypesID = feedsNewsTypesID
        }
        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_FEEDS, putObject,'POST',
        null, 
         () => {
            componentMethods.addError(componentConstants.language.LANG_FEEDS_SUCCESSFULLY_UPDATED)
         }, 
        {}, 
        false, 
        () => {
            componentMethods.addError(componentConstants.language.LANG_FEEDS_NOT_UPDATED)
        }, () => {
            componentMethods.addError(componentConstants.language.LANG_FEEDS_NOT_UPDATED)

        })
    }
  return (

            <TableRow>
                <TableCell>
                </TableCell>
                <TableCell>
                    <TextField label={componentConstants.language.LANG_FEEDS_NAME} variant="outlined" color="secondary" value={feedsName} fullWidth onChange={handleChangeFeedsName} />
                </TableCell>
                <TableCell>
                <TextField label={componentConstants.language.LANG_FEEDS_URL} variant="outlined" color="secondary" value={feedsUrl} fullWidth onChange={handleChangeFeedsUrl} />
                </TableCell>
                <TableCell>
                {componentMethods.getManagerState().newsTypes ?
                    <Select
                        fullWidth
                        value={feedsNewsTypesID}
                        label={componentConstants.language.LANG_SELECT_LANGUAGE}
                        onChange={handleChangeNewsTypesID}
                        >
                        {
                            componentMethods.getManagerState().newsTypes.map((item, index)=> {
                            return <MenuItem key={index} value={item.newsTypesID}>{item.newsTypesName}</MenuItem>
                            })
                        }
                        </Select>
                    :
                    null
                }
                </TableCell>
                <TableCell>

                </TableCell>
                <TableCell>

                </TableCell>               
                <TableCell>

                </TableCell>                
                <TableCell>
                </TableCell>
                <TableCell>
                    <IconButton onClick={postFeedAction}  color="secondary"><AddIcon /></IconButton>
                </TableCell>
            </TableRow>
  );
}