import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Drawer from '@mui/material/Drawer';
import ListItemText from '@mui/material/ListItemText';
import Style from '../../NewsFeed/Styles/NewsFeedStyle'
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled, useTheme } from '@mui/material/styles';
import TuneIcon from '@mui/icons-material/Tune';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));
const drawerWidth = 350;

export default function SwipeableTemporaryDrawer(props) {
  const theme = useTheme();
  const classes = Style();
  const componentConstants = {
      ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [filterSubscribers, setFilterSubscribers] = React.useState("")
  const [open, setOpen] = React.useState(false);
  const [askDelete, setAskDelete] = React.useState({})
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const isAskDelete = (subscribersID) =>
  {
    return askDelete[subscribersID]
  }

  const toggleAskDelete = (subscribersID) => {
    let newAskDelete = {...askDelete}
    newAskDelete[subscribersID] = !newAskDelete[subscribersID]

    setAskDelete(newAskDelete)
  }
  const isWordSubscribed = () => {
    let isWordSubscribed = false;
    componentMethods.getManagerState().subscribers.map((item, index) => {
        if(item.subscribersKeyword.toLowerCase() === filterSubscribers.toLowerCase()) {
            isWordSubscribed = true
        }
    })
    return isWordSubscribed;
  }

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Fab color="secondary" onClick={() => {handleDrawerOpen(anchor, true)}} style={classes.subscriptionsDrawer} aria-label="add">
            <TuneIcon />
          </Fab>
          <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="right"
          open={open}
        >
        <DrawerHeader style={{position: "sticky", top: 0, backgroundColor:"#fff", zIndex: 10000}}>
        <TextField id="outlined-basic" color="secondary" fullWidth label={componentConstants.language.LANG_FILTER_SUBSCRIBERS} variant="outlined" onChange={(e) => { setFilterSubscribers(e.target.value)}}/>

        <IconButton onClick={handleDrawerClose}>
           <ChevronRightIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <ListItem key={componentConstants.language.LANG_BOOKMARKS} disablePadding>
          <Divider />
        </ListItem>
          {filterSubscribers != "" && !isWordSubscribed() ?
                <ListItem>
                  <Button color="secondary" variant="outlined" style={classes.addToWatchlistButton} onClick={() => {componentMethods.postSubscriberKeyword(filterSubscribers)}}>{componentConstants.language.LANG_ADD_TO_WATCHLIST} {filterSubscribers}</Button>
                </ListItem>
                :
                null
            }
            {
              componentMethods.getManagerState().subscribers.map((item, index)=> {
                if(item.subscribersKeyword.toLowerCase().trim().indexOf(filterSubscribers.toLowerCase().trim()) >= 0) {
                  if(isAskDelete(item.subscribersID)) {
                    return <React.Fragment>
                                <Button color="secondary" variant="contained" style={classes.addToWatchlistButton} onClick={() => {componentMethods.deleteSubscriberKeyword(item.subscribersID)}}>{componentConstants.language.LANG_DELETE} {item.subscribersKeyword}</Button>
                                <IconButton onClick={(e) => {toggleAskDelete(item.subscribersID)}}><CancelIcon /></IconButton>
                            </React.Fragment>
                  } else {
                    return <ListItem key={index} disablePadding>
                            <a href={"/subscribers/" + item.subscribersID} color="primary" style={classes.menuDrawerLinks}>
                              <ListItemButton color="primary">
                                <ListItemText primary={item.subscribersKeyword} />
                              </ListItemButton>
                            </a> 
                            <IconButton onClick={(e) => {toggleAskDelete(item.subscribersID)}}><DeleteIcon /></IconButton>
                          </ListItem>
                  }
                } else {
                  return null;
                }
              })
          }

        <Divider />
      </List>
    </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}