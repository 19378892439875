import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import Style from './Styles/HomeStyle'
import Settings from '../../Settings/Settings';
import Appbar from '../Appbar/Appbar';
import Login from '../Login/Login'
import NewsFeed from '../NewsFeed/NewsFeed'
import SearchFeed from '../SearchFeed/SearchFeed';
import Typography from '@mui/material/Typography';
import TrendingTodayLinks from '../TrendingTodayLinks/TrendingTodayLinks';
import CookiesSnackbarComponent from '../CookiesSnackbarComponent/CookiesSnackbarComponent';
import NewsItemPage from '../NewsItemPage/NewsItemPage';
import LinksFromPagesFeed from '../LinksFromPagesFeed/LinksFromPagesFeed';
import LatestNewsFeed from '../NewsFeed/LatestNewsFeed';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import FeedIcon from '@mui/icons-material/Feed';
import Button from '@mui/material/Button';
import TrendingWordFooter from '../TrendingWordsFooter/TrendingWordFooter';
export default class HomeComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            urlParams: props.urlParams
        }
    }

    componentDidMount() {
        if(this.props.urlParams && this.props.urlParams.query) {
            this.getLinksAction(this.props.urlParams.query)
            this.getLinksFromPagesAction(this.props.urlParams.query)
        } else if (!this.getLocalStorage('user')){
            this.getLinksAction("")
        }
        super.componentDidMount()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            clearErrorMessage: (index) => {
                that.props.clearErrorMessage(index)
            },
            getSubscribersFeedAction: () => {
                that.getSubscribersFeedAction()
            },
            getLinksAction: (q) => {
                that.getLinksAction(q)
            },
            postSubscriberKeyword: (subscribersKeyword) => {
                that.postSubscriberKeyword(subscribersKeyword)
            },
            getTrendingLinkxFeed: () => {
                that.getTrendingLinkxFeed()
            },
            getSubscribersLatestFeedAction: () => {
                that.getSubscribersLatestFeedAction()
            }
        })
    }
    postSubscriberKeyword(subscribersKeyword) {
        const that = this;
        let postObj = {
            subscribersKeyword: subscribersKeyword,
            subscribersDeliveryPeriod: 0,
            subscribersActive: 1
        }
        if(that.getLocalStorage('user')) {
            that.ajax(Settings.API_URL + Settings.API_ENDPOINT_SUBSCRIBERS + '?limit=4&accountToken=' + that.getLocalStorage('user').tokensHash,postObj,'POST',null, (data) => {
                let subscribers = that.props.getManagerState().subscribers;
                subscribers = subscribers.concat(data.items[0])
                that.props.setManagerState({subscribers: subscribers})
            })
        }    
    }
    setFeedType(type)
    {
        this.props.setManagerState({feedType:type}, () => {
            switch(type)
            {
                case 'latest':
                    this.getSubscribersLatestFeedAction()
                    break;
                case 'merged':
                    this.getSubscribersFeedAction();
                    break;
            }
        })
    }
    getSubscribersFeedAction() {
        const that = this;
        let subscribersIDRequest = ''
        if(that.props.getManagerState().subscribersID) {
            const subscribersID = [parseInt(that.props.getManagerState().subscribersID)]
            subscribersIDRequest += '&subscribersID[0]=' + subscribersID;
        }
        let newsFeedItemsLimit = this.getLocalStorage('newsFeedItemsLimit') ? this.getLocalStorage('newsFeedItemsLimit').newsFeedItemsLimit : this.props.getManagerState().newsFeedItemsLimit
        that.props.setManagerState({loadingNewsFeed: true, subscribersFeedItems: []},() => {
            if(that.getLocalStorage('user')) {
                that.ajax(Settings.API_URL + Settings.API_ENDPOINT_SUBSCRIBERS_FEED_ALL + '?limit=' + newsFeedItemsLimit+ '&accountToken=' + that.getLocalStorage('user').tokensHash + subscribersIDRequest,{},'GET','subscribersFeed', (data) => {
                    that.props.setManagerState({oldestLinksID:0 ,subscribersFeedItems: data.items, loadingNewsFeed: false})

                })
            }    
        })
    }

    getSubscribersLatestFeedAction() {
        const that = this;

        that.props.setManagerState({loadingLatestNewsFeed: true},() => {
            if(that.getLocalStorage('user')) {
                that.ajax(Settings.API_URL + Settings.API_ENDPOINT_SUBSCRIBERS_FEED_LATEST,{},'GET',null, (data) => {
                    that.props.setManagerState({subscribersLatestFeedItems: data.items, loadingLatestNewsFeed: false})
                })
            }    
        })
    }
    getLinksAction(q) {
        const that = this;
        that.props.setManagerState({loadingSearchFeed: true, searchFeedItems: []},() => {
            if (q !== "")
            {
                let strict = 0
                if (this.getQueryParams('strict') === '1')
                {
                    strict = 1   
                }
                that.ajax(Settings.API_URL + Settings.API_ENDPOINT_GET_LINKS_V3 + '?q=' + q + '&strict=' + strict,{},'GET',null, (data) => {
                    that.props.setManagerState({searchFeedItems: data.items, loadingSearchFeed: false})
                }, () => {
                    that.props.setManagerState({loadingSearchFeed: false})
                })                
            } else {
                that.ajax(Settings.API_URL + Settings.API_ENDPOINT_GET_LINKS + '?limit=24&q=' + q,{},'GET',null, (data) => {
                    that.props.setManagerState({searchFeedItems: data.items, loadingSearchFeed: false})
                }, () => {
                    that.props.setManagerState({loadingSearchFeed: false})
                })    
            }
        })
    }


    getLinksFromPagesAction(q) {
        const that = this;
        that.props.setManagerState({linksFromPagesFeedItems: []},() => {
            if (q !== "")
            {
                let strict = 0
                if (this.getQueryParams('strict') === '1')
                {
                    strict = 1   
                }
                that.ajax(Settings.API_URL + Settings.API_ENDPOINT_GET_LINKS_FROM_PAGES_V3 + '?q=' + q + '&strict=' + strict,{},'GET',null, (data) => {
                    that.props.setManagerState({linksFromPagesFeedItems: data.items})
                })                
            }
        })
    }
    
    getTrendingLinkxFeed() {
        const that = this;
        that.props.setManagerState({loadingTrendingFeed: true, trendingFeedItems: []},() => {
            that.ajax(Settings.API_URL + Settings.API_ENDPOINT_GET_TRENDING_LINKS + '?limit=8',{},'GET',null, (data) => {
                that.props.setManagerState({trendingFeedItems: data.items, loadingTrendingFeed: false})
            }, () => {
                that.props.setManagerState({loadingTrendingFeed: false})
            })
        })
    }
    getNewsFeedByType()
    {
        const classes = Style();

        switch(this.props.getManagerState().feedType)
        {
            case 'latest':
                return <React.Fragment>
                        <Button style={classes.feedTypeButton} variant='outlined' color="secondary" onClick={ () => { this.setFeedType('merged')}} ><DynamicFeedIcon /></Button>
                        <Button style={classes.feedTypeButton} variant='contained' onClick={ () => { this.setFeedType('latest')}}><FeedIcon /></Button>
                        <LatestNewsFeed {...this.buildComponentProps('Appbar')} />
                    </React.Fragment>
            case 'merged':
                return <React.Fragment>
                        <Button style={classes.feedTypeButton} variant='contained' onClick={ () => { this.setFeedType('merged')}} ><DynamicFeedIcon /></Button>
                        <Button style={classes.feedTypeButton} variant='outlined' color="secondary" onClick={ () => { this.setFeedType('latest')}}><FeedIcon /></Button>
                        <NewsFeed {...this.buildComponentProps('Appbar')} />
                    </React.Fragment>
        }
    }

    getRender() {
        const classes = Style();
        this.loadComponentsProps()
        return <div style={classes.root}>
                    <Appbar postWebsiteVisitors={true} {...this.buildComponentProps('Appbar')} />
                    <div style={classes.pageContainer}>
                        <Login {...this.buildComponentProps('Appbar')} />
                        {!this.getLocalStorage('user') ?
                            <div style={{marginTop: 10}}>
                                <SearchFeed withoutMargin={true} {...this.buildComponentProps('Appbar')} />
                            </div>
                        :   
                            <React.Fragment>
                                {
                                    this.props.urlParams && this.props.urlParams.query?
                                        <div style={{marginTop: 10}}>
                                            <SearchFeed withoutMargin={true} {...this.buildComponentProps('Appbar')} />
                                            <LinksFromPagesFeed withoutMargin={true} {...this.buildComponentProps('Appbar')} />
                                        </div>
                                        :
                                        <React.Fragment>
                                            {this.getNewsFeedByType()}
                                        </React.Fragment>
                                }
                            </React.Fragment>
                        }
                        {
                            this.props.getManagerState().subscribers.length === 0 && this.getLocalStorage('user') ?
                            <React.Fragment>
                                <Typography component="p" style={{margin:"0 auto", textAlign:"center"}}>{this.props.getManagerState().language.LANG_SEARCH_OR_ADD_WORDS_TO_WATCHLIST}</Typography>
                                <TrendingTodayLinks  withoutMargin={true} {...this.buildComponentProps('Appbar')} />
                            </React.Fragment>
                            :
                            null
                        }
                        <CookiesSnackbarComponent {...this.buildComponentProps('Appbar')} />
                        <TrendingWordFooter  {...this.buildComponentProps('Appbar')} />
                    </div>
                </div>
    }
}
