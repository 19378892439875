import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import Style from '../NewsFeed/Styles/NewsFeedStyle'
import Settings from '../../Settings/Settings';
import NewsItem from '../NewsFeed/Subcomponents/NewsItem';
import CircularProgress from '../CircularProgress/CircularProgress';
import Grid from '@mui/material/Grid';
import Appbar from '../Appbar/Appbar';
import IconButton from '@mui/material/IconButton';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Button from '@mui/material/Button';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Paper from '@mui/material/Paper';

export default class TrendingBlacklistWords extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
        }
    }

    componentDidMount() {
        super.componentDidMount()
        this.getTrendingWordsAction()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },

            addError: (error, invalidFields = {}) => {
                that.addErrorMain(error, invalidFields)
            },
            addErrorMain: (error, invalidFields = {}) => {
                that.addErrorMain(error, invalidFields)
            },
            clearErrorMessage: (index) => {
                that.props.clearErrorMessage(index)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}, errorCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback, errorCallback);
            },
            saveLocalStorage: (storageObject, storageName) => {
                that.saveLocalStorage(storageObject, storageName)
            }
        })
    }
    getTrendingWordsAction(page = 1) {
        const that = this;
        const date = new Date();
        const dateYear = date.getFullYear();
        let dateMonth = date.getMonth();
        dateMonth++;
        if(date.getMonth() < 10) {
            dateMonth = '0' + dateMonth
        }
        let dateDay = date.getDate();
        if(dateDay < 10) {
            dateDay = '0' + dateDay;
        }
        let searchDate = dateYear + '-' + dateMonth + '-' + dateDay;
        that.props.setManagerState({loadingTrendingWords: true},() => {
            that.ajax(Settings.API_URL + Settings.API_ENDPOINT_TRENDING_BLACKLIST + '?page=' + page +'&limit=50&accountToken=' + that.getLocalStorage('user').tokensHash  ,{},'GET',null, (data) => {
                if(data.items.length) {
                    that.props.setManagerState({trendingWords: data.items, loadingTrendingWords:false, trendingPagination: data.pagination})
                } else {
                    that.props.setManagerState({loadingTrendingWords:false})
                }
            })
        })
    }

    // getTrendingWordsAction(page = 1) {
    //     const that = this;
    //     const date = new Date();
    //     const dateYear = date.getFullYear();
    //     let dateMonth = date.getMonth();
    //     dateMonth++;
    //     if(date.getMonth() < 10) {
    //         dateMonth = '0' + dateMonth
    //     }
    //     let dateDay = date.getDate();
    //     if(dateDay < 10) {
    //         dateDay = '0' + dateDay;
    //     }
    //     let searchDate = dateYear + '-' + dateMonth + '-' + dateDay;
    //     that.props.setManagerState({loadingTrendingWords: true},() => {
    //         that.ajax(Settings.API_URL + Settings.API_ENDPOINT_TRENDING_WHITELIST + '?page=' + page +'&limit=50&accountToken=' + that.getLocalStorage('user').tokensHash  ,{},'GET',null, (data) => {
    //             if(data.items.length) {
    //                 that.props.setManagerState({trendingWords: data.items, loadingTrendingWords:false})
    //             } else {
    //                 that.props.setManagerState({loadingTrendingWords:false})
    //             }
    //         })
    //     })
    // }

    deleteTrendingBlacklistAction(trendingWhitelistID) {
        const that = this;
        that.ajax(Settings.API_URL + Settings.API_ENDPOINT_TRENDING_BLACKLIST + '/' + trendingWhitelistID + '?accountToken=' + that.getLocalStorage('user').tokensHash  ,{},'DELETE')
    }
    postTrendingBlacklistAction(trendingBlacklistKeyword) {
        const that = this;
        that.ajax(Settings.API_URL + Settings.API_ENDPOINT_TRENDING_BLACKLIST,{trendingBlacklistKeyword: trendingBlacklistKeyword},'POST', null, () => {
            that.addErrorMain(this.props.getManagerState().language.LANG_BLACKLIST_WORD_POST_SUCCESSFUL)
        })
    }

    postTrendingWhitelistAction(trendingWhitelistKeyword) {
        const that = this;
        that.ajax(Settings.API_URL + Settings.API_ENDPOINT_TRENDING_WHITELIST ,{trendingWhitelistKeyword: trendingWhitelistKeyword},'POST',null, () =>{
            that.addErrorMain(this.props.getManagerState().language.LANG_WHITELIST_WORD_POST_SUCCESSFUL)
        })
    }
    setPage(page)
    {
        this.setState({page: page})
    }
    getRender() {
        const classes = Style();
        this.loadComponentsProps()
        return <div style={classes.root}>
                    <Appbar {...this.buildComponentProps('Appbar')} />
                    {
                        !this.props.getManagerState().loadingTrendingWords && this.props.getManagerState().trendingWords.length === 0 ?
                        <Typography style={classes.cilcularProgress} variant="body2">{this.props.getManagerState().language.LANG_EMPTY_TRENDING_WHITELISTED_LIST}</Typography>
                        :
                        null
                    }
                    {
                        this.props.getManagerState().loadingTrendingWords ?
                                <CircularProgress style={classes.cilcularProgress} text={this.props.getManagerState().language.LANG_GENERATING_FEED} />
                            :
                            <Grid container>
                            {
                                this.props.getManagerState().trendingWords.map((item, index)=> {
                                    return <Grid item xs={12} md={4} lg={3} key={index}>
                                                <Paper style={{margin: 8, padding: 8}}>
                                                        <Button style={{margin: 8}} onClick={(e) => { 
                                                            this.postTrendingWhitelistAction(item.trendingBlacklistKeyword)
                                                            this.deleteTrendingBlacklistAction(item.trendingBlacklistID)
                                                        } } variant="outlined" color="secondary" endIcon={<ThumbUpIcon />}></Button>
                                                        <a href={"/search/" + item.trendingBlacklistKeyword}>{item.trendingBlacklistKeyword}</a> 
                                                    <IconButton style={{padding: 8}} onClick={(e) => { this.deleteTrendingBlacklistAction(item.trendingBlacklistID)} } color="secondary"><RemoveCircleIcon /></IconButton>
                                                </Paper>
                                            </Grid>
                                    })
                            }
                            </Grid>
                    }
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            {this.state.wordsPage != 1 ?
                            <IconButton color="secondary" onClick={() => {this.getTrendingWordsAction(this.props.getManagerState().trendingPagination.current-1)}}>
                                <ArrowBackIosNewIcon />
                            </IconButton>
                            :
                            null
                            }
                            
                            {this.props.getManagerState().language.LANG_PAGE}: <TextField id="pagination" label={this.props.getManagerState().language.LANG_PAGE} variant="outlined" value={this.state.page} onChange={(e) => {this.setPage(e.target.value)}} /> of {this.props.getManagerState().trendingPagination.last}
                            <Button color="secondary" onClick={() => {this.getTrendingWordsAction(this.state.page)}}>{this.props.getManagerState().language.LANG_GO_TO_PAGE}</Button>
                            
                            
                            {this.props.getManagerState().trendingPagination.current != this.props.getManagerState().trendingPagination.last ?
                            <IconButton color="secondary" onClick={() => {this.getTrendingWordsAction(this.props.getManagerState().trendingPagination.current+1)}}>
                                <NavigateNextIcon />
                                </IconButton>
                            :
                            null
                            }
                        </Grid>
                    </Grid>                                        
                </div>
    }
}
