import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import Style from '../NewsFeed/Styles/NewsFeedStyle'
import Settings from '../../Settings/Settings';
import CircularProgress from '../CircularProgress/CircularProgress';
import Grid from '@mui/material/Grid';
import Appbar from '../Appbar/Appbar';
import Button from '@mui/material/Button';
import FeedForm from './Subcomponents/FeedForm';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddFeedForm from './Subcomponents/AddFeedForm';
import SearchFeedForm from './Subcomponents/SearchFeedForm';

export default class AdminFeedsEditor extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
        }
    }

    componentDidMount() {
        super.componentDidMount()
        this.getFeedsAction()
        this.getNewsTypesAction()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('Appbar', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Appbar', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            getFeedsAction: (query = null) => {
                this.getFeedsAction(query)
            },
            addError: (error, invalidFields = {}) => {
                that.addErrorMain(error, invalidFields)
            },
            addErrorMain: (error, invalidFields = {}) => {
                that.addErrorMain(error, invalidFields)
            },
            clearErrorMessage: (index) => {
                that.props.clearErrorMessage(index)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}, errorCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback, errorCallback);
            },
            saveLocalStorage: (storageObject, storageName) => {
                that.saveLocalStorage(storageObject, storageName)
            }
        })
    }
    getFeedsAction(query = null) {
        const that = this;
        let page = 1
        if (this.state.wordsPage)
            page = this.state.wordsPage

        this.setState({wordsPage:page})
        that.props.setManagerState({feedsItems: [], loadingFeeds: true},() => {
            let url = Settings.API_URL + Settings.API_ENDPOINT_FEEDS + '?page=' + page + '&limit=50&orderBy=feedsID'
            if (query)
            {
                url += '&q=' + query
            }
            that.props.setManagerState({searchFeedsQuery: query})
            that.ajax(url,{},'GET',null, (data) => {
                if(data.items.length) {
                    that.props.setManagerState({feedsItems: data.items, loadingFeeds:false, feedsPagination: data.pagination})
                } else {
                    that.props.setManagerState({loadingFeeds:false})
                }
            })
        })
    }

    getNewsTypesAction() {
        const that = this;
        let page = 1
        if (this.state.wordsPage)
            page = this.state.wordsPage

        this.setState({wordsPage:page})
        that.props.setManagerState({newsTypes: [], loadingNewsTypes: true},() => {
            that.ajax(Settings.API_URL + Settings.API_ENDPOINT_NEWS_TYPES_ACTIVE ,{},'GET',null, (data) => {
                if(data.items.length) {
                    that.props.setManagerState({newsTypes: data.items, loadingNewsTypes:false})
                }
            })
        })
    }
    nextPage() {
        let that = this;
        this.setState({wordsPage : this.props.getManagerState().feedsPagination.current + 1}, () => {
            that.getFeedsAction(this.props.getManagerState().searchFeedsQuery)
        })
    }
    previousPage()
    {
        let that = this;
        this.setState({wordsPage : this.props.getManagerState().feedsPagination.current - 1}, () => {
            that.getFeedsAction(this.props.getManagerState().searchFeedsQuery)
        })
    }
    handleChangePage (e) 
    {
        this.setState({wordsPage: e.target.value})
    }
    getRender() {
        const classes = Style();
        this.loadComponentsProps()
        return <div style={classes.rootWords}>
                    <Appbar {...this.buildComponentProps('Appbar')} />
                    <Paper style={classes.paperWords}>
                    {
                        this.props.getManagerState().loadingFeeds ?
                                <CircularProgress style={classes.cilcularProgress} text={this.props.getManagerState().language.LANG_GENERATING_FEED} />
                            :
                            <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <SearchFeedForm {...this.buildComponentProps('Appbar')} />
                                    <AddFeedForm {...this.buildComponentProps('Appbar')} />
                                    <TableRow>
                                        <TableCell>{this.props.getManagerState().language.LANG_FEEDS_ID}</TableCell>
                                        <TableCell>{this.props.getManagerState().language.LANG_FEEDS_NAME}</TableCell>
                                        <TableCell>{this.props.getManagerState().language.LANG_FEEDS_NEWS_TYPE}</TableCell>
                                        <TableCell>{this.props.getManagerState().language.LANG_FEEDS_HTTP_STATUS_CODE}</TableCell>
                                        <TableCell>{this.props.getManagerState().language.LANG_FEEDS_ACTIVE}</TableCell>
                                        <TableCell>{this.props.getManagerState().language.LANG_FEEDS_PROCID}</TableCell>
                                        <TableCell>{this.props.getManagerState().language.LANG_FEEDS_LAST_CRAWLED_DATE}</TableCell>
                                        <TableCell>{this.props.getManagerState().language.LANG_ACTION}</TableCell>
                                    </TableRow>
                                </TableHead>
                                    <TableBody>
                                    {
                                        this.props.getManagerState().feedsItems.map((item, index)=> {
                                            return <FeedForm key={index} feed={item}  {...this.buildComponentProps('Appbar')} />
                                            })
                                    }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                    }   
                        <Grid container spacing={2} style={{margin:8}}>
                            <Grid item xs={12} md={12} lg={12}>
                                {this.state.wordsPage != 1 ?
                                <IconButton color="secondary" onClick={() => {this.previousPage()}}>
                                    <ArrowBackIosNewIcon />
                                </IconButton>
                                :
                                null
                                }
                                
                                {this.props.getManagerState().language.LANG_PAGE}: <TextField id="pagination" label={this.props.getManagerState().language.LANG_PAGE} variant="outlined" value={this.state.wordsPage} onChange={(e) => {this.handleChangePage(e)}} /> of {this.props.getManagerState().feedsPagination.last}
                                
                                <Button color="secondary" onClick={() => {this.getFeedsAction(this.props.getManagerState().searchFeedsQuery)}}>{this.props.getManagerState().language.LANG_GO_TO_PAGE}</Button>
                                
                                {this.props.getManagerState().feedsPagination.current != this.props.getManagerState().feedsPagination.last ?
                                <IconButton color="secondary" onClick={() => {this.nextPage()}}>
                                    <NavigateNextIcon />
                                    </IconButton>
                                :
                                null
                                }
                            </Grid>
                        </Grid>
                    </Paper>                     
                </div>
    }
}
