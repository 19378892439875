import React, { useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Settings from '../../../Settings/Settings'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SearchIcon from '@mui/icons-material/Search';

export default function SearchFeedForm(props) {
    const [searchQuery, setSearchQuery] = React.useState(props.componentMethods.getManagerState().searchFeedsQuery)
    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const componentProps = {
        ...props.componentProps
    }
    const handleChangeSearchQuery = (e) =>
    {
        setSearchQuery(e.target.value)
    }
    
    const searchFeedActions = () => {
        componentMethods.getFeedsAction(searchQuery)
    }
  return (

            <TableRow>
                <TableCell>
                </TableCell>
                <TableCell colSpan={6}>
                    <TextField label={componentConstants.language.LANG_FEEDS_SEARCH} variant="outlined" color="secondary" value={searchQuery} fullWidth onChange={handleChangeSearchQuery} />
                </TableCell>
                <TableCell>
                    <IconButton onClick={searchFeedActions}  color="secondary"><SearchIcon /></IconButton>
                </TableCell>
            </TableRow>
  );
}