import color from '@mui/material/colors/purple';

export default function Style(theme) {
  const style = {
    root: {
      
    },
    logo: {
      color: color[500],
      fontWeight: 700
    },
    dropdownRoot: {
      margin: 8
    },
    refreshFab: {
      position: 'fixed',
      left: 10,
      bottom: 10
    },
    showFabMenu: {
      position: 'fixed',
      right: 10,
      bottom: 10
    },
  }
    return style
}