import React, { useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Settings from '../../../Settings/Settings'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function FeedForm(props) {
    const [feedsID, setFeedsID] = React.useState(props.feed.feedsID)
    const [feedsUrl, setFeedsUrl] = React.useState(props.feed.feedsUrl)
    const [feedsName, setFeedsName] = React.useState(props.feed.feedsName)
    const [feedsLastCrawled, setFeedsLastCrawled] = React.useState(props.feed.feedsLastCrawled)
    const [feedsNewsTypesID, setFeedsNewsTypesID] = React.useState(props.feed.feedsNewsTypesID)
    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const componentProps = {
        ...props.componentProps
    }
    const handleChangeFeedsName = (e) =>
        {
            setFeedsName(e.target.value)
        }
    const handleChangeNewsTypesID = (e) =>
        {
            setFeedsNewsTypesID(e.target.value)
        }
    const putFeedAction = () => {
        const that = this;
        let putObject = {feedsActive: 1, feedsName: feedsName, feedsNewsTypesID: feedsNewsTypesID}

        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_FEEDS + '/' +  feedsID + '?accountToken=' + componentMethods.getLocalStorage('user').tokensHash  ,putObject,'PUT',
        null, 
         () => {
            componentMethods.addError(componentConstants.language.LANG_FEEDS_SUCCESSFULLY_UPDATED)
         }, 
        {}, 
        false, 
        () => {
            componentMethods.addError(componentConstants.language.LANG_FEEDS_NOT_UPDATED)
        }, () => {
            componentMethods.addError(componentConstants.language.LANG_FEEDS_NOT_UPDATED)

        })
    }
  return (

            <TableRow>
                <TableCell>
                    {props.feed.feedsID}
                </TableCell>
                <TableCell>
                    <TextField fullWidth label={componentConstants.language.LANG_CORRECT_WORD} variant="outlined" color="secondary" value={feedsName} onChange={handleChangeFeedsName} />
                    <br />
                    <a target="_blank" href={props.feed.feedsUrl}>{props.feed.feedsUrl}</a>
                </TableCell>
                <TableCell>
                    <Select
                    value={feedsNewsTypesID}
                    label={componentConstants.language.LANG_SELECT_LANGUAGE}
                    onChange={handleChangeNewsTypesID}
                    >
                    {
                        componentMethods.getManagerState().newsTypes.map((item, index)=> {
                        return <MenuItem key={index} value={item.newsTypesID}>{item.newsTypesName}</MenuItem>
                        })
                    }

                    </Select>
                </TableCell>
                <TableCell>
                    {props.feed.feedsHttpStatusCode}
                </TableCell>
                <TableCell>
                    {props.feed.feedsCrawling}
                </TableCell>               
                <TableCell>
                    {props.feed.feedsProcId}
                </TableCell>                
                <TableCell>
                    {props.feed.feedsLastCrawled}
                </TableCell>
                <TableCell>
                    <IconButton onClick={putFeedAction}  color="secondary"><CheckCircleIcon /></IconButton>
                </TableCell>
            </TableRow>
  );
}